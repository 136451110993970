<template>
  <v-dialog v-model="value" @click:outside="close" scrollable max-width="90vw" >
    <v-container fluid class="pa-0">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>{{ item.title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="close" icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card>
    {{artworkByLocation}}
      <v-card-subtitle class="artwork-title">
        This work is available in the following product ranges...
      </v-card-subtitle>
      <v-card-text>
      <date-range @getDates="getSales"></date-range>
        <v-data-table
          v-if="artworkByLocation.data"
          sort-by="quantity"
          sort-desc="true"
          :headers="headers"
          :items="artworkByLocation.data[0]?.locations"
          class="elevation-1"
          :loading="loading"
        >
          <template v-slot:[`item.commission`]="{ item }">
            £{{item.commission.toFixed(2)}}
          </template>
        </v-data-table>
      <hr>
      {{artworkByProduct.data}}
      </v-card-text>
    </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import ReportApi from '@/api/publishedArtist/report'
import DateRange from '@/components/DateRange'
// import ImageThumbnail from '@/components/ImageThumbnail.vue'
export default {
  components: {
    DateRange
    // ImageThumbnail,
  },
  props: {
    value: Boolean,
    item: Number,
    items: Array
  },
  data () {
    return {
      imageUrl: process.env.VUE_APP_IMG_URL,
      loading: true,
      artworkByLocation: [],
      artworkByProduct: [],
      headers: [
        {
          text: 'Location',
          value: 'location'
        },
        {
          text: 'Commission',
          value: 'commission'
        },
        {
          text: 'Quantity',
          value: 'quantity'
        },
      ]
    }
  },
  created: async function () {
  },
  computed: mapState({
  }),
  methods: {
    async getSales (dates) {
      this.loading = true
      this.artworkByLocation = await ReportApi.loadArtistSalesByArtworkAndLocation({dateRange: dates, artworkCode: this.items[this.item]})
      this.artworkByProduct = await ReportApi.loadArtistSalesByArtworkAndProduct({dateRange: dates, artworkCode: this.items[this.item]})
      this.loading = false
    },
    close () {
      this.$emit('close', this.item)
    }
  }
}
</script>
<style scoped>
.artwork-title {
  font-size: 1.1rem
}
</style>
