<template>
  <div>
    <h1 class="display-1 font-weight-bold mx-1 my-3">
      Artworks from the published range of products...
    </h1>
    <p>Click an item for more detailed analysis</p>
    <v-row>
      <v-col :key="$index" v-for="(item, $index) in artworks.data" xl= "2" lg="3" sm="4" cols="12" class="d-flex flex-column">
        <v-card
        :id="`item${$index}`"
        :key="$index"
        outlined
        class="mx-0 my-0 d-flex flex-column flex-grow-1 v-clickable"
      >
        <v-card-text class="d-flex flex-column pb-0">
          <!-- <v-img v-for="(product, index) of item.products" :key="index" :src="`${imageUrl}/100px/${product.artworkImage}`" ></v-img> -->
          <v-img @click="showProducts($index)" :src="`${imageUrl}/300px/${item.image}`">
          </v-img>
        </v-card-text>
        <v-card-subtitle class="artwork-title text-center">
          {{ item.title }}
        </v-card-subtitle>
      </v-card>
      </v-col>
    </v-row>
    <v-overlay :value="loaded">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <artwork-products-dialog v-if="openDialog" v-model='openDialog' :items='artworkCodes' :item='selectedItem' @close="closeDialog" />
  </div>
</template>

<script>
import ArtworkProductsDialog from '@/views/PublishedArtist/Artworks/ArtworkProductsDialog.vue'
import DateRange from '@/components/DateRange'
import ArtworkApi from '@/api/artwork'
import _ from 'lodash'

export default {
  props: {
  },
  components: {
    ArtworkProductsDialog,
    DateRange
  },
  data () {
    return {
      artworks: [],
      openDialog: false,
      loaded: false,
      imageUrl: process.env.VUE_APP_IMG_URL,
      dateRange: ["2021-12-01", "2025-01-01"]
    }
  },
  computed: {
    artworkCodes () {
      return this.artworks.data?.map( artwork => artwork.artworkCode)
    }
  },
  created: async function () {
    this.artworks = await ArtworkApi.loadArtistArtworks()
    // this.artworks = await ReportApi.loadArtistCommissionByArtwork({dateRange: this.dateRange})
    // this.artworksByLocation = await ReportApi.loadArtistSalesByArtworkAndLocation({dateRange: this.dateRange})
  },
  methods: {
    cleanMessage(item) {
      return this.$sanitize(item);
    },
    getArtworkImage (products) {
      if (products.length === 1) {
        return products[0].artworkImage
      } else if (products[0].category === 'Mugs') {
        return products[1].artworkImage
      } else return products[0].artworkImage
    },
    showProducts (item) {
      this.selectedItem = item
      this.openDialog = true
    },
    closeDialog (element) {
      this.openDialog = false
    }
  }
}
</script>
<style scoped>
.artwork-title {
  font-size: 1.1rem
}
</style>
